<template>
  <div>
    <ch-layout page search button>
      <template #search>
        <div style="width: 100%;" class="searchWrapper">
          <ch-search
              :render-option="searchOption" :model="searchForm"
              :props="{ labelWidth: '66px'}">
          </ch-search>
        </div>
      </template>
      <template #button>
        <ch-button
            :loading="isButtonLoading"
            @click="handleExport">导出</ch-button>
      </template>

      <template v-slot:default>
        <ch-table :render-option="tableOption" :data="tableList" show-summary
                  :summary-method="getSummaries"
                  ref="multipleTable"
                  :loading="isTableLoading"
                  :props="{height: '100%', size: 'mini',}">
        </ch-table>
      </template>

      <template v-slot:page>
        <ch-page
            type="old"
            ref="page"
            :getListFunction="getTableList"
        ></ch-page>
      </template>
    </ch-layout>

  </div>
</template>
<script>
import {exportFile, getTreeSelect} from "@/utils/utils";
export default {
  data() {
    return {
      tableList: [],
      selectList: [],
      isTableLoading: false,
      treeSelect: {
        export: false,
      },
      // 搜索相关
      searchForm: {
        companyId: null,
        orderNumber: null,
        payType: null,
        payee: null,
        serveNumber: null,
        time:null,
        createTimeEnd:null,
        createTimeStart:null,
      },
      isButtonLoading: false, // 导出
      payeeOption: [
        {label: '服务人员', value: 3},
        {label: '商户合计', value: 5},
        {label: '手续费', value: 6},
      ],
      payee:{
        0:'分佣金额',1:'平台', 2:'商户', 3:'服务人员', 4:'客户', 6:'手续费',7:'物业'
      },
      payTypeOption:{
        0:'分佣',
        1:'微信支付',
        2:'余额支付',
        3:'先付后用',
      }
    }
  },
  computed: {
    tableOption: function () {
      return [
        {column: 'type', prop: '', type: 'selection', width: '25px'},
        {column: 'type', label: '序号', prop: '', type: 'index', width: '50'},
        {column: 'text', label: '交易订单号', prop: 'orderNumber', showOverflowTooltip: true },
        {column: 'text', label: '服务订单号', prop: 'serveNumber', showOverflowTooltip: true },
        {column: 'text', label: '增购服务订单号', prop: 'appreciationNumber', showOverflowTooltip: true },
        {column: 'text', label: '支付方式', prop: 'payType', showOverflowTooltip: true,width: '80px' },
        {column: 'text', label: '业务名称', prop: 'title',width: '120px', showOverflowTooltip: true},
        {column: 'text', label: '入帐方', prop: 'payee', showOverflowTooltip: true,width: '80px' },
        {column: 'cash', label: '交易金额(元)', prop: 'amount', showOverflowTooltip: true,width: '110px' },
        {column: "text", label: "创建时间", prop: "createTime", showOverflowTooltip: true,},
      ]
    },
    searchOption: function () {
      return [
        {type: 'input',  label: '商户交易订单号', prop: 'orderNumber', placeholder: "请输入",labelWidth: '120px'},
        {type: 'input',  label: '商户服务订单号', prop: 'serveNumber', placeholder: "请输入",labelWidth: '120px'},
        {type: 'time',  label: '创建时间', prop: 'time', placeholder: "请选择"},
        {type: 'select',  label: '入账方', prop: 'payee', placeholder: "请选择",
          name: "label",  value: "value", option: this.payeeOption},
        {type: 'select',  label: '支付方式', prop: 'payType', placeholder: "请选择",
          name: "label",  value: "value", option: [
            {label: '分佣', value: 0},
            {label: '微信支付', value: 1},
          ]},
        {type: 'button', button: [
            {text: '查询', onClick: this.searchHandle},
            {text: '重置', onClick: this.searchHandle},
          ]},
      ]
    },
  },
  created() {
    // 获取操作显示权限
    getTreeSelect(this.$route.name).then((res) => {
      this.treeSelect = res
    })
  },
  mounted() {
    this.getTableList()
  },
  methods: {
    // 查询
    searchHandle() {
      if(this.searchForm.time){
        const [start, end] = this.searchForm.time
        this.searchForm.createTimeStart = start
        this.searchForm.createTimeEnd = end
      }
      this.getTableList()
    },
    // 列表数据
    getTableList(searchForm=this.searchForm) {
      this.isTableLoading = true
      this.$refs.page?.getServerList('/hm/report/transactionList', searchForm).then(res => {
        let rows = res.rows;

        this.tableList = rows.map((item) => {
          return{
            ...item,
            payType:this.payTypeOption[item.payType]||'一',
            payee:this.payee[item.payee]
          }
        })
        this.isTableLoading = false
      }).catch(() => {
        this.isTableLoading = false
      })
    },
    // 导出
    handleExport() {
      this.isButtonLoading = true
      exportFile('/client/reportExcel/endExpense', this.params).then(()=> {
        this.isButtonLoading = false
      }).catch(()=> this.isButtonLoading = false)
    },
    // 合计
    getSummaries(param) {
      this.$nextTick(() => {
        this.$refs.multipleTable.getTableRef().doLayout();
      })
      // 定义参数接收回调数据
      const {columns, data} = param;
      const sums = [];
      columns.forEach((column, index) => {
        let values = [];
        //amount
        if (column.property === 'amount') {
          values = data.map((item) => Number(item[column.property]));
        }
        // 使用判断遍历需要计数的列
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              // 接口返回与展示单位不一样时需手动计算，防止小数点后相加报错，也可直接 toFixed()
              return (Number(prev * 100 + curr * 100) / 100).toFixed(2);
            } else {
              return prev;
            }
          }, 0);
        } else {
          sums[index] = '';
        }
        // 放在最后防止foreach遍历后不显示自定义名字
        if (index === 2) {
          sums[index] = '合计';
        }
      });
      return sums;
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
